import { Image } from "@/generated/graphql";
import { isGif } from "./is-gif";

export function sectionMaxHeight(
  image: Image | undefined | null,
  isOneElement: boolean | undefined
) {
  if (!isOneElement || !image || isGif(image)) {
    return undefined;
  }

  return image.height!;
}
