import React, { FC } from "react";
import styled from "styled-components";
import { FlexCol } from "../../../shared/globals";
import { SectionCardWrapperProps } from "../../types";
import { SectionBackgroundTypeEnum } from "../../../generated/graphql";

const SectionCardWrapper: FC<SectionCardWrapperProps> = ({
  children,
  isSlider,
  background,
  aspectRatio,
  maxHeight,
}) => {
  return (
    <CardWrapper
      isSlider={isSlider}
      background={background}
      $aspectRatio={aspectRatio}
      $maxHeight={maxHeight}
    >
      {children}
    </CardWrapper>
  );
};

export default SectionCardWrapper;

const CardWrapper = styled(FlexCol)<{
  isSlider?: boolean;
  $aspectRatio?: number;
  $maxHeight?: number;
  background?: {
    color?: string;
    type: SectionBackgroundTypeEnum;
  };
}>`
  height: ${({ isSlider }) => (isSlider ? "calc(100% - 40px)" : "auto")};
  width: ${({ isSlider }) => (isSlider ? "auto" : "100%")};
  ${({ $maxHeight }) => $maxHeight && `max-height: ${$maxHeight}px`}; // Point 2
  position: relative;
  background-color: ${({ background }) =>
    background?.type === SectionBackgroundTypeEnum.Color
      ? background?.color
      : "transparent"};
  padding: 10px;
  border-radius: 4px;
  margin-bottom: ${({ isSlider }) => (isSlider ? "20px" : "0")};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
  cursor: pointer;
  ${({ $aspectRatio }) =>
    $aspectRatio && `aspect-ratio: ${$aspectRatio}`}; // Point 1
`;

/**
  * Point 1 *
  The default case for collection section and attribute section is each element to have aspect ratio with 1 (square shape)
  we need to pass the aspect ratio so we could have a calculated width and height and then each element reserve its place
  during the first render, so we can improve the layout-shift measured by core-web-vitals metrics

  * Point 2 *
  we have a corner case for Point 1, which is we have a section with only one element. if that element have an image with a height 
  smaller then the calculated height from the aspect-ratio, then we need to keep the original height in that case.
 */
