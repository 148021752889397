import { Banner, Image } from "@/generated/graphql";
import { isGif } from "./is-gif";
import { FormattedDataType } from "@/templates/types";

export function sectionAspectRatio(
  image: Image | undefined | null,
  isOneElement: boolean | undefined
) {
  if (isGif(image) && isOneElement) {
    return undefined;
  }

  // default for attribute and collection sections
  return 1;
}

function getImageAspectRatio(image: Image | undefined | null) {
  return (image?.width || 1) / (image?.height || 1);
}

function getImageGifAspectRatio(image: Image | undefined | null) {
  if (isGif(image)) {
    // Gif has different image size, so we cannot keep the ratio
    return 0;
  }

  // for banners, we need to keep the aspect ratio as it is
  return getImageAspectRatio(image);
}

export type TResponsiveAspectRatio = { desktop: number; mobile: number };

function getBannerAspectRatio(banner: Banner): TResponsiveAspectRatio {
  return {
    desktop: getImageGifAspectRatio(banner.image ?? banner.mobileImage),
    mobile: getImageGifAspectRatio(banner.mobileImage ?? banner.image),
  };
}

/**
 * For banners that have more than an image per row, we need to set an equal aspect-ratio for all of them.
 * In this function, we will set them all with the smallest aspect ratio among them.
 * Output an array that each element of it is the aspect ratio for the corresponding index.
 */
export function getGridBannerAspectRatios(
  section: Partial<FormattedDataType>
): TResponsiveAspectRatio[] {
  const itemsPerRow = section.itemsPerRow || 1;
  const banners = section.banners || [];
  const result: TResponsiveAspectRatio[] = [];

  // Iterate over the banners array in chunks based on itemsPerRow
  for (let i = 0; i < banners.length; i += itemsPerRow) {
    // Get the current row slice
    const row = banners.slice(i, i + itemsPerRow);

    // For desktop, find the minimum aspect ratio in this row
    const aspectRatioForDesktop = Math.min(
      ...row.map((banner) => getBannerAspectRatio(banner).desktop)
    );

    // For mobile, we display only one element, so keep its aspect ratio
    const aspectRatiosForMobile = row.map(
      (banner) => getBannerAspectRatio(banner).mobile
    );

    const rowResult = aspectRatiosForMobile.map((aspectRatioForMobile) => ({
      desktop: aspectRatioForDesktop,
      mobile: aspectRatioForMobile,
    }));

    result.push(...rowResult);
  }

  return result;
}

/**
 * For slider banners, we need to return the smallest aspect ratio of all banners
 */

export function getSliderBannersAspectRatio(
  section: Partial<FormattedDataType>
): TResponsiveAspectRatio[] {
  const banners = section.banners || [];

  // for desktop, get the minimum for all
  const minAspectRatioForDesktop = Math.min(
    ...banners.map((banner) => getBannerAspectRatio(banner).desktop)
  );

  // for desktop, get the minimum for all
  const minAspectRatioForMobile = Math.min(
    ...banners.map((banner) => getBannerAspectRatio(banner).mobile)
  );

  return banners.map(() => ({
    desktop: minAspectRatioForDesktop,
    mobile: minAspectRatioForMobile,
  }));
}
