import { FormattedMessage } from "@/i18n/i18n-client";
import { SelectedFiltersValues } from "../styled";
import { useSearchParams } from "@/i18n/i18n-navigation";
import { parseSearchParams } from "@/shared/utils/search-params";

const SelectedPriceRange = () => {
  const searchParams = useSearchParams();
  const queries = parseSearchParams(searchParams);
  const minPrice = queries.minPrice;
  const maxPrice = queries.maxPrice;

  if (minPrice && maxPrice) {
    return (
      <SelectedFiltersValues>
        <FormattedMessage defaultMessage="From" id="dM+p3/" /> {minPrice}{" "}
        <FormattedMessage defaultMessage="To" id="9j3hXO" /> {maxPrice}
      </SelectedFiltersValues>
    );
  }
  if (minPrice) {
    return (
      <SelectedFiltersValues>
        Price {">"} {minPrice}
      </SelectedFiltersValues>
    );
  }
  if (maxPrice) {
    return (
      <>
        <SelectedFiltersValues>
          Price {"<"} {maxPrice}
        </SelectedFiltersValues>
      </>
    );
  }
  return null;
};

export default SelectedPriceRange;
