import React, { FC } from "react";
import styled from "styled-components";
import { FlexCol } from "../../../shared/globals";
import { SectionCardWrapperProps } from "../../types";
import { SectionBackgroundTypeEnum } from "../../../generated/graphql";

const SectionCardWrapper: FC<SectionCardWrapperProps> = ({
  children,
  isCollection,
  isSlider,
  background,
  aspectRatio,
  maxHeight,
}) => {
  return (
    <CardWrapper
      isCollection={isCollection}
      isSlider={isSlider}
      background={background}
      $aspectRatio={aspectRatio}
      $maxHeight={maxHeight}
    >
      {children}
    </CardWrapper>
  );
};

export default SectionCardWrapper;

const CardWrapper = styled(FlexCol)<{
  isCollection?: boolean;
  isSlider?: boolean;
  $aspectRatio?: number;
  $maxHeight?: number;
  background?: {
    color?: string;
    type: SectionBackgroundTypeEnum;
  };
}>`
  height: ${({ isSlider }) => (isSlider ? "calc(100% - 40px)" : "auto")};
  width: ${({ isSlider }) => (isSlider ? "auto" : "100%")};
  ${({ $maxHeight }) => $maxHeight && `max-height: ${$maxHeight}px`};
  position: relative;
  background-color: ${({ background }) =>
    background?.type === SectionBackgroundTypeEnum.Color
      ? background?.color
      : "transparent"};
  padding: ${({ isCollection }) => (isCollection ? "0" : "10px")};
  border-radius: ${({ isCollection }) => (isCollection ? "0" : "4px")};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14);
  cursor: pointer;
  margin-bottom: ${({ isSlider }) => (isSlider ? "20px" : "0")};
  ${({ $aspectRatio }) => $aspectRatio && `aspect-ratio: ${$aspectRatio}`};
`;
