import { FC, useState } from "react";
import styled from "styled-components";
import { FormattedMessage } from "@/i18n/i18n-client";
import { Sorting } from "./Sorting";
import { PrimaryButton } from "@/components/Button";
import { Filter as FilterIcon } from "@/assets/Icons/Filter";
import { Section } from "@/shared/layout";
import FilterTags from "./components/FilterTags";
import Filter from "./Filter";
import { FilterAndSortProps } from "../../../types";
import { useSearchParams } from "@/i18n/i18n-navigation";

const DesktopFilterSort: FC<FilterAndSortProps> = ({ collectionPageId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const searchParams = useSearchParams();
  const searchQuery = searchParams.get("query");

  return (
    <Section background="backgroundReverse">
      <Filter
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        collectionPageId={collectionPageId}
      />
      <div>
        <ItemsWrapper>
          <RowWrapper>
            <FilterBox>
              <PrimaryButton
                prefixIcon={<FilterIcon />}
                onClick={() => setIsOpen(true)}
              >
                <FormattedMessage defaultMessage="Filter" id="9Obw6C" />
              </PrimaryButton>
            </FilterBox>
            {searchQuery && (
              <>
                <FormattedMessage defaultMessage="Searched for " id="EY3l3D" />{" "}
                {'"' + searchQuery + '"'}
              </>
            )}
            <Sorting />
          </RowWrapper>
          <RowWrapper>
            <FilterTags />
          </RowWrapper>
        </ItemsWrapper>
      </div>
    </Section>
  );
};

export default DesktopFilterSort;

// Styles

const ItemsWrapper = styled.div`
  display: none;
  align-items: center;
  place-content: center;
  padding: 30px 0 0;
  justify-content: space-between;
  margin: auto;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

export const RowWrapper = styled.div<{
  border?: boolean;
}>`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: ${({ border }) => (border ? ` 1px solid #d8d8d8;` : "unset")};
  @media (min-width: 768px) {
    justify-content: space-between;
    display: flex;
  }
`;

const FilterBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

// TODO: showing number of products
// const ProductCounter = styled(Span)`
//   font-size: 12px;
//   font-weight: bold;
//   line-height: 1.5;
//   color: #969696;
//   ${rtl("margin: 0 16px 0 0 ", "margin: 0 0 0 16px;")};
// `;
