import { useRouter } from "@/i18n/i18n-navigation";
import { TLanguage } from "@/i18n/i18n-settings";
import { useCallback, useTransition } from "react";

type RouterOptions = {
  scroll?: boolean | undefined;
  locale?: TLanguage | undefined;
};

type RouterFunction = "push" | "replace" | "back" | "forward" | "refresh";

/**
 * This hook return a isPending state that would be true if we are navigating to another page
 * Also return a navigate function which is basically a router.push or router.replace
 * @returns [isPending: boolean, navigate function(pathname, options)]
 */
export function useNavigateLoading(func: RouterFunction = "push") {
  const [isPending, startTransition] = useTransition();
  const router = useRouter();

  const navigate = useCallback(
    (href: string, options?: RouterOptions) => {
      startTransition(() => {
        router[func](href, options);
      });
    },
    [router, func]
  );

  return [isPending, navigate] as const;
}
