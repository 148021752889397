import React from "react";
import { FlexRow } from "@/shared/globals";
import { HeroBtnLink } from "@/components/Hero/HeroBtnLink";
import { LeftSmallArrow } from "@/assets/Icons/LeftSmallArrow";
import { RightSmallArrow } from "@/assets/Icons/RightSmallArrow";
import type { HeroProps } from "../../types";
import { Photo } from "@/shared/globals/UiElements/Photo";
import {
  HeroBox,
  HeroContainer,
  Heading,
  ButtonsWrapper,
  HeroBtn,
  SecondButton,
  NavigationContainer,
  NavigationCircle,
  ButtonHolder,
  Text,
} from "@/components/Hero";

const Hero: React.FC<HeroProps> = ({
  heroSettings,
  activeSlide,
  isButton1Exist,
  isButton2Exist,
  image,
  handleNavigationButtons,
  setActiveSlide,
  hasOverlay,
}) => {
  return (
    <HeroBox align={activeSlide?.align!}>
      {image && (
        <Photo
          loading="eager"
          src={image || ""}
          objectFit="cover"
          priority
          absolute
        />
      )}
      <HeroContainer hasOverlay={hasOverlay!}>
        <Heading
          headingColor={activeSlide?.subHeadingColor!}
          align={activeSlide?.align!}
        >
          {activeSlide?.headingText}
        </Heading>
        <Text
          subHeadingColor={activeSlide?.subHeadingColor!}
          subHeadingSize={String(activeSlide?.subHeadingSize!)}
          align={activeSlide?.align!}
        >
          {activeSlide?.subHeadingText}
        </Text>
        <ButtonsWrapper align={activeSlide?.align!}>
          {!isButton1Exist ? null : (
            <HeroBtnLink {...activeSlide?.buttons?.[0]?.link}>
              <HeroBtn>{activeSlide?.buttons?.[0]?.text}</HeroBtn>
            </HeroBtnLink>
          )}
          {!isButton2Exist ? null : (
            <HeroBtnLink {...activeSlide?.buttons?.[1]?.link}>
              <SecondButton>{activeSlide?.buttons?.[1]?.text}</SecondButton>
            </HeroBtnLink>
          )}
        </ButtonsWrapper>
      </HeroContainer>
      {heroSettings?.length! > 1 && (
        <NavigationContainer>
          <FlexRow>
            {heroSettings?.map((hero, i) => {
              return (
                <NavigationCircle
                  key={hero?.id}
                  className={hero?.id === activeSlide?.id ? "active" : ""}
                  onClick={() => setActiveSlide?.(heroSettings?.[i])}
                />
              );
            })}
          </FlexRow>
          <FlexRow>
            <ButtonHolder
              prefixIcon={<LeftSmallArrow />}
              reversed
              onClick={() => handleNavigationButtons?.("previous")}
            />
            <ButtonHolder
              suffixIcon={<RightSmallArrow />}
              reversed
              onClick={() => handleNavigationButtons?.("next")}
            />
          </FlexRow>
        </NavigationContainer>
      )}
    </HeroBox>
  );
};

export default Hero;
