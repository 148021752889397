import { FormattedMessage } from "@/i18n/i18n-client";
import { ProductSortByField, SortOrder } from "../../../../generated/graphql";

export const sortOptions = [
  {
    key: `${ProductSortByField.Title}-${SortOrder.Asc}`,
    value: { sortBy: "title", sortOrder: SortOrder.Asc },
    label: <FormattedMessage defaultMessage="Name" id="HAlOn1" />,
  },
  {
    key: `${ProductSortByField.Price}-${SortOrder.Asc}`,
    value: { sortBy: ProductSortByField.Price, sortOrder: SortOrder.Asc },
    label: <FormattedMessage defaultMessage="Price: low to high" id="nn8Lto" />,
  },
  {
    key: `${ProductSortByField.Price}-${SortOrder.Desc}`,
    value: { sortBy: ProductSortByField.Price, sortOrder: SortOrder.Desc },
    label: <FormattedMessage defaultMessage="Price: high to low" id="vV6SHW" />,
  },
];

export enum DefaultFilters {
  Price = "Price",
  Collections = "Product Collections",
}
