export function createSearchParams(
  obj: Record<
    string,
    string | number | undefined | (string | number | undefined)[]
  >
) {
  const params = new URLSearchParams(
    Object.entries(obj).flatMap(([key, values]) => {
      if (!values) {
        return [];
      }
      if (Array.isArray(values)) {
        return values.flatMap((value) =>
          !value ? [] : [[key, String(value)]]
        );
      }
      return [[key, String(values)]];
    })
  );
  const query = params.toString();
  return { params, query };
}

export function parseSearchParams<T = any>(params: URLSearchParams) {
  const result = {} as T;
  for (const [key, value] of params) {
    if (typeof result[key] === "string") {
      result[key] = [result[key], value];
    } else if (Array.isArray(result[key])) {
      result[key] = [...result[key], value];
    } else {
      result[key] = value;
    }
  }
  return result;
}
