import { FC } from "react";
import styled from "styled-components";
import { GridGallerySectionProps } from "../../types";
import { Breakpoints } from "../../../shared/globals/types";

const GridGallerySection: FC<GridGallerySectionProps> = ({
  children,
  isProduct,
  itemsPerRow,
}) => {
  return (
    <GridContainer isProduct={isProduct} itemsPerRow={itemsPerRow}>
      {children}
    </GridContainer>
  );
};

export default GridGallerySection;

const GridContainer = styled.div<{ itemsPerRow?: number; isProduct?: boolean }>`
  display: grid;
  grid-template-columns: repeat(${({ itemsPerRow }) => itemsPerRow}, 1fr);
  gap: ${({ isProduct }) => isProduct && "16px"};
  @media (max-width: ${Breakpoints["TABLET"]}px) {
    grid-template-columns: repeat(
      ${({ isProduct }) => (isProduct ? 2 : 1)},
      1fr
    );
  }
`;
