import groupBy from "lodash/groupBy";
import { IntlShape } from "@/i18n/i18n-client";
import {
  ProductAttributeValue,
  AttributeValueSelector,
  ProductCollection,
  ProductOptionValue,
  OptionValueSelector,
} from "@/generated/graphql";

interface Dictionary<T> {
  [index: string]: T;
}

export type FiltersDictionary = Dictionary<
  {
    propertyName: string;
    propertyId: string;
    valueName: string;
    valueId: string;
  }[]
>;

interface GetAvailableFiltersProps {
  attributesProductsDistribution: ProductAttributeValue[] | null | undefined;
  collectionsProductsDistribution: ProductCollection[] | null | undefined;
  optionsProductsDistribution: ProductOptionValue[] | null | undefined;
  intl: IntlShape;
}
export const getAvailableFilters = ({
  attributesProductsDistribution,
  collectionsProductsDistribution,
  optionsProductsDistribution,
  intl,
}: GetAvailableFiltersProps) => {
  const collectionsData = (collectionsProductsDistribution || [])
    ?.filter(
      (collection) =>
        !collection?.isDeleted &&
        !collection?.isArchived &&
        collection?.isVisible
    )
    .map((collection) => ({
      propertyName: intl.formatMessage({
        defaultMessage: "Product Collections",
        id: "oV52BO",
      }),
      propertyId: "c",
      valueName: collection?.title!,
      valueId: "c:" + collection?.id!,
    }));

  const attributesData = (attributesProductsDistribution || [])?.map(
    (attribute) => ({
      propertyName: attribute?.attribute?.name!,
      propertyId: attribute?.attribute?.id!,
      valueName: attribute?.name!,
      valueId: "a:" + attribute?.id!,
    })
  );

  const optionsData = (optionsProductsDistribution || [])
    ?.map((option) => ({
      propertyName: option?.option?.name!,
      propertyId: option?.option?.id!,
      valueName: option?.name!,
      valueId: "o:" + option?.id!,
    }))
    ?.filter(Boolean);
  const availableFilters = [
    ...collectionsData,
    ...attributesData,
    ...optionsData,
  ];
  const filtersDictionary = groupBy(
    availableFilters,
    (item) => item?.propertyId!
  );
  return { filtersDictionary };
};

export const getFiltersSelectors = (selectedFilters: string[]) => {
  const collectionIds: string[] = [];
  const attributeValueSelectors: AttributeValueSelector[] = [];
  const optionValueSelectors: OptionValueSelector[] = [];
  (typeof selectedFilters === "string"
    ? [selectedFilters]
    : selectedFilters
  ).forEach((filter) => {
    if (filter.startsWith("c:")) {
      collectionIds.push(filter.split(":")[1]);
    } else if (filter.startsWith("a:")) {
      const attributeSelector = {
        attributeId: filter.split("|")[1],
        attributeValueId: filter.split(":")[1].split("|")[0],
      };
      attributeValueSelectors.push(attributeSelector);
    } else if (filter.startsWith("o:")) {
      const optionSelector = {
        optionId: filter.split("|")[1],
        optionValueId: filter.split(":")[1].split("|")[0],
      };
      optionValueSelectors.push(optionSelector);
    }
  });
  return { collectionIds, attributeValueSelectors, optionValueSelectors };
};
