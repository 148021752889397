import React, { useState } from "react";
import styled from "styled-components";
import { Swiper } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Section } from "@/shared/layout";
import type { HomeGallerySectionProps } from "../../types";
import { rtl } from "@/shared/styles-utils";
import { ArrowRight } from "@/assets/Icons/ArrowRight";
import { FlexRow } from "@/shared/globals";
import useBreakPoints from "@/shared/utils/useBreakPoints";
import { DisplayAsEnum, SectionTypeEnum } from "@/generated/graphql";
import SectionGenerator from "../components/HomeGallerySection/SectionGenerator";
import { StoreTemplate } from "../../TemplateLoader";
import { getColumnCount } from "@/shared/utils/homeHelpers";
import ReactSwiperCss from "@/shared/globals/LazyElements/react-swiper";

const HomeGallerySection: React.FC<HomeGallerySectionProps> = ({ section }) => {
  const Template = StoreTemplate.get();
  const { isTablet } = useBreakPoints();
  const [prevEl, setPrevEl] = useState<HTMLSpanElement | null>(null);
  const [nextEl, setNextEl] = useState<HTMLSpanElement | null>(null);
  const { columnCount } = getColumnCount(section);
  const slidesPerView = isTablet ? 1 : section.itemsPerRow;

  return (
    <StyledSection stretchToFullWidth={section?.stretchToFullWidth}>
      <Template.SectionHeading section={section} />
      {section.displayAs === DisplayAsEnum.Slider ? (
        <SliderContainer spacing="m">
          <SlideButtons ref={(node) => setPrevEl(node)}>
            <ArrowRight />
          </SlideButtons>
          <ReactSwiperCss />
          <Swiper
            slidesPerView={slidesPerView}
            spaceBetween={20}
            navigation={{ prevEl, nextEl }}
            modules={[Navigation, Pagination, Autoplay]}
            pagination={{ dynamicBullets: true, clickable: true }}
            loop={isTablet || columnCount >= section.itemsPerRow!}
            style={{
              width: "100%",
              padding:
                section?.type === SectionTypeEnum.ProductsRow ? "8px" : "0",
            }}
            autoplay={{
              delay: 4000,
              pauseOnMouseEnter: true,
              disableOnInteraction: false,
            }}
          >
            {SectionGenerator({ section, isSliderSection: true })}
          </Swiper>

          <SlideButtons right ref={(node) => setNextEl(node)}>
            <ArrowRight />
          </SlideButtons>
        </SliderContainer>
      ) : (
        <>
          <Template.GridGallerySection
            isProduct={section?.type === SectionTypeEnum.ProductsRow}
            itemsPerRow={section.itemsPerRow}
          >
            <SectionGenerator section={section} />
          </Template.GridGallerySection>
        </>
      )}
    </StyledSection>
  );
};

export default HomeGallerySection;

/**
 *
 * Styles
 *
 */

const StyledSection = styled(Section)<{ stretchToFullWidth?: boolean }>`
  && {
    max-width: ${({ stretchToFullWidth }) => stretchToFullWidth && "100vw"};
    padding: ${({ stretchToFullWidth }) => stretchToFullWidth && "unset"};
  }
`;

const SliderContainer = styled(FlexRow)`
  .swiper-pagination-bullets-dynamic {
    bottom: 0;
  }
`;

const SlideButtons = styled.span<{ right?: boolean }>`
  cursor: pointer;

  svg {
    transform: ${({ right }) =>
      right ? rtl("rotate(180deg)", "") : rtl("", "rotate(180deg)")};
    color: #5f738c;
    width: 30px;
    height: 30px;
  }
`;
