import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ApolloError } from "@apollo/client";
import { Section } from "../styled";
import {
  useParams,
  usePathname,
  useSearchParams,
} from "@/i18n/i18n-navigation";
import {
  createSearchParams,
  parseSearchParams,
} from "@/shared/utils/search-params";
import { useNavigateLoading } from "@/hooks/useNavigate";
import { Spinner } from "@/shared/globals";

interface FilterByCategoryProps {
  availableFilters: {
    propertyName: string;
    propertyId: string;
    valueName: string;
    valueId: string;
    count?: number;
  }[];
  loading: boolean;
  error: ApolloError | undefined;
  selectedFilterIds: string[];
}

export const AvailableFilterChoices = ({
  availableFilters: availableFiltersProp,
  loading,
  error,
  selectedFilterIds,
}: FilterByCategoryProps) => {
  const [isFetching, navigate] = useNavigateLoading();
  const params = useParams();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const queries = parseSearchParams(searchParams);
  const isCollectionPage = !!params.collection;

  const [availableFilters, setAvailableFilters] =
    useState(availableFiltersProp);

  useEffect(() => {
    if (availableFiltersProp?.length) {
      setAvailableFilters(availableFiltersProp);
    }
  }, [availableFiltersProp]);

  if (error && !availableFilters) {
    return null;
  }

  availableFilters?.sort((a, b) => {
    const fa = a.valueName,
      fb = b.valueName;

    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  });
  return (
    <Section>
      <StyledLoading>
        {isFetching && <Spinner inline size={24} />}
      </StyledLoading>
      <StyledUL>
        {availableFilters?.map((filter, i) => (
          <StyledLI key={i}>
            <StyledCheckBox
              type="checkbox"
              checked={
                selectedFilterIds?.includes(filter.valueId) ||
                selectedFilterIds?.includes(
                  filter?.valueId! + "|" + filter?.propertyId!
                )
              }
              id={filter?.valueId!}
              disabled={loading}
              onChange={(event) => {
                let filters = [...selectedFilterIds];
                if (event.target.checked) {
                  if (filter.valueId.startsWith("c:"))
                    filters.push(filter?.valueId!);
                  else
                    filters.push(filter?.valueId! + "|" + filter?.propertyId!);
                } else {
                  if (filter.valueId.startsWith("c:"))
                    filters = filters.filter(
                      (item) => item !== filter?.valueId!
                    );
                  else
                    filters = filters.filter(
                      (item) =>
                        item !== filter?.valueId! + "|" + filter.propertyId
                    );
                  if (isCollectionPage) {
                    navigate(`/shop`);
                    return;
                  }
                }
                if (queries.collection) {
                  filters.push(
                    availableFilters?.find(
                      (item) => item.valueName === queries.collection
                    )?.valueId!
                  );
                }

                const { query } = createSearchParams({
                  ...queries,
                  page: 1,
                  filters,
                });

                navigate(
                  isCollectionPage ? `/shop?${query}` : `${pathname}?${query}`,
                  { scroll: false }
                );
              }}
            />

            <StyledLabel htmlFor={filter.valueId!} disabled={loading}>
              {filter?.valueName}
            </StyledLabel>
            {/* 
              TODO: Add the number of products for each filter when enabling the filters 
              <StyledSubTitle>({filter?.count})</StyledSubTitle> 
            <StyledSubTitle>({filter?.count})</StyledSubTitle>
              <StyledSubTitle>({filter?.count})</StyledSubTitle> 
            */}
          </StyledLI>
        ))}
      </StyledUL>
    </Section>
  );
};

const StyledLoading = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  z-index: -1;
  width: 85%;
`;

const StyledUL = styled.ul`
  list-style-type: none;
  padding-inline-start: 0;
`;
const StyledLI = styled.li`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`;
const StyledLabel = styled.label<{ disabled }>`
  ${({ disabled }) => disabled && "opacity: 0.5;"}
  margin-inline-start: 10px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed;" : "pointer;")};
`;
const StyledCheckBox = styled.input<{ disabled }>`
  ${({ disabled }) => disabled && "opacity: 0.5;"}
  cursor: ${({ disabled }) => (disabled ? "not-allowed;" : "pointer;")};
`;
// TODO: use this when enabling the filters
// const StyledSubTitle = styled.h3`
//   font-size: 12px;
//   font-weight: 500;
//   margin-inline-start: 10px;
//   color: #353535;
// `;
