import React, { FC, Fragment } from "react";
import { SectionTypeEnum } from "@/generated/graphql";
import { FormattedDataType } from "@/templates/types";
import { SwiperSlide } from "swiper/react";
import VideoCard from "./cards/VideoCard";
import RenderCollectionCard from "./cards/RenderCollectionCard";
import RenderBannerCard from "./cards/RenderBannerCard";
import RenderAttributeCard from "./cards/RenderAttributeCard";
import RenderProductCard from "./cards/RenderProductCard";
import {
  getSliderBannersAspectRatio,
  getGridBannerAspectRatios,
} from "@/shared/utils/aspect-ratio";

interface SectionGeneratorProps {
  section: Partial<FormattedDataType>;
  isSliderSection?: boolean;
}

const SectionGenerator: FC<SectionGeneratorProps> = ({
  section,
  isSliderSection,
}) => {
  if (section.type === SectionTypeEnum.Video) {
    return section?.links?.map((link, index) =>
      isSliderSection ? (
        <SwiperSlide key={index} style={{ height: "auto" }}>
          <VideoCard link={link} section={section} isSliderSection />
        </SwiperSlide>
      ) : (
        <Fragment key={index}>
          <VideoCard link={link} section={section} />
        </Fragment>
      )
    );
  }

  if (section.type === SectionTypeEnum.CollectionsRow) {
    return section?.collections?.map((collection) =>
      isSliderSection ? (
        <SwiperSlide
          key={collection?.id}
          style={{
            height: "auto",
          }}
        >
          <RenderCollectionCard
            collection={collection}
            section={section}
            isSliderSection
            isOneElement={section.itemsPerRow === 1}
          />
        </SwiperSlide>
      ) : (
        <Fragment key={collection?.id}>
          <RenderCollectionCard
            collection={collection}
            section={section}
            isOneElement={section.itemsPerRow === 1}
          />
        </Fragment>
      )
    );
  }

  if (section.type === SectionTypeEnum.Banner) {
    const gridAspectRatios = getGridBannerAspectRatios(section);
    const sliderAspectRatios = getSliderBannersAspectRatio(section);
    return section?.banners?.map((banner, index) =>
      isSliderSection ? (
        <SwiperSlide key={banner?.id} style={{ height: "auto" }}>
          <RenderBannerCard
            banner={banner}
            section={section}
            isSliderSection
            aspectRatio={sliderAspectRatios[index]}
          />
        </SwiperSlide>
      ) : (
        <Fragment key={banner?.id}>
          <RenderBannerCard
            banner={banner}
            section={section}
            aspectRatio={gridAspectRatios[index]}
          />
        </Fragment>
      )
    );
  }

  if (section.type === SectionTypeEnum.AttributesRow) {
    return section?.attributeValues?.map((attributeValue) =>
      isSliderSection ? (
        <SwiperSlide key={attributeValue?.id} style={{ height: "auto" }}>
          <RenderAttributeCard
            attributeValue={attributeValue}
            section={section}
            isSliderSection
            isOneElement={section.itemsPerRow === 1}
          />
        </SwiperSlide>
      ) : (
        <Fragment key={attributeValue?.id}>
          <RenderAttributeCard
            attributeValue={attributeValue}
            section={section}
            isOneElement={section.itemsPerRow === 1}
          />
        </Fragment>
      )
    );
  }

  if (section.type === SectionTypeEnum.ProductsRow) {
    return section?.products?.map((product) =>
      isSliderSection ? (
        <SwiperSlide key={product?.id} style={{ height: "auto" }}>
          <RenderProductCard
            product={product}
            section={section}
            isSliderSection
          />
        </SwiperSlide>
      ) : (
        <Fragment key={product?.id}>
          <RenderProductCard product={product} section={section} />
        </Fragment>
      )
    );
  }

  return null;
};

export default SectionGenerator;
