import ProductCardRenderer from "@/Renderers/ProductCardRenderer";
import { StoreTemplate } from "@/templates/TemplateLoader";
import { FormattedDataType } from "@/templates/types";
import React from "react";

interface RenderProductCardProps {
  product: FormattedDataType["products"][0];
  section: Partial<FormattedDataType>;
  isSliderSection?: boolean;
}

const RenderProductCard: React.FC<RenderProductCardProps> = ({
  product,
  section,
  isSliderSection,
}) => {
  const Template = StoreTemplate.get();
  return (
    <Template.SectionCardWrapper
      data-test="product-card"
      background={section?.background}
      isSlider={isSliderSection}
      isProduct
    >
      <ProductCardRenderer
        Component={Template.ProductCard}
        product={product}
        onlyCardBody
        isSlider={isSliderSection}
      />
    </Template.SectionCardWrapper>
  );
};

export default RenderProductCard;
