const Filter = () => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16">
      <path
        fill="currentColor"
        d="M7.2 0C6.159 0 5.278.671 4.947 1.6H1.6c-.289-.004-.557.148-.702.397-.146.249-.146.557 0 .806.145.25.413.401.702.397h3.347c.331.929 1.212 1.6 2.253 1.6 1.323 0 2.4-1.077 2.4-2.4S8.523 0 7.2 0zM12 1.6c-.289-.004-.557.148-.702.397-.146.249-.146.557 0 .806.145.25.413.401.702.397h4c.289.004.557-.148.702-.397.146-.249.146-.557 0-.806-.145-.25-.413-.401-.702-.397h-4zm-.8 4c-1.041 0-1.922.671-2.253 1.6H1.6c-.289-.004-.557.148-.702.397-.146.249-.146.557 0 .806.145.25.413.401.702.397h7.347c.331.929 1.212 1.6 2.253 1.6 1.323 0 2.4-1.077 2.4-2.4s-1.077-2.4-2.4-2.4zM16 7.2c-.289-.004-.557.148-.702.397-.146.249-.146.557 0 .806.145.25.413.401.702.397.289.004.557-.148.702-.397.146-.249.146-.557 0-.806-.145-.25-.413-.401-.702-.397zm-11.2 4c-1.041 0-1.922.671-2.253 1.6H1.6c-.289-.004-.557.148-.702.397-.146.249-.146.557 0 .806.145.25.413.401.702.397h.947c.331.929 1.212 1.6 2.253 1.6 1.323 0 2.4-1.077 2.4-2.4s-1.077-2.4-2.4-2.4zm4.8 1.6c-.289-.004-.557.148-.702.397-.146.249-.146.557 0 .806.145.25.413.401.702.397H16c.289.004.557-.148.702-.397.146-.249.146-.557 0-.806-.145-.25-.413-.401-.702-.397H9.6z"
      />
    </svg>
  );
};

export { Filter };
