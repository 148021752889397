import { StoreTemplate } from "@/templates/TemplateLoader";
import { ReactNode } from "react";
import SectionCardWrapper from "@/templates/farah-omar/elements/SectionCardWrapper";
import GridGallerySection from "@/templates/farah-omar/elements/GridGallerySection";
import CollectionCard from "@/templates/farah-omar/elements/CollectionCard";

// default
import Hero from "@/templates/default/elements/Hero";
import HomeAbout from "@/templates/default/elements/HomeAbout";
import HomeContentSection from "@/templates/default/elements/HomeContentSection";
import HomeGallerySection from "@/templates/default/elements/HomeGallerySection";
import SectionHeading from "@/templates/default/elements/SectionHeading";
import FilterAndSort from "@/templates/default/elements/FilterAndSort";
import ProductsList from "@/templates/default/elements/ProductsList";
import ProductCard from "@/templates/default/components/HomeGallerySection/cards/ProductCard";
import BannerCard from "@/templates/default/components/HomeGallerySection/cards/BannerCard";
import AttributeValueCard from "@/templates/default/components/HomeGallerySection/cards/AttributeValueCard";
import SectionContent from "@/templates/default/elements/SectionContent";
import PageHeader from "@/templates/default/elements/PageHeader";

const elements = {
  Hero,
  HomeAbout,
  HomeContentSection,
  HomeGallerySection,
  SectionHeading,
  SectionCardWrapper,
  GridGallerySection,
  CollectionCard,
  FilterAndSort,
  ProductsList,
  ProductCard,
  BannerCard,
  AttributeValueCard,
  SectionContent,
  PageHeader,
};

export function StorePagesLoader(props: { children: ReactNode }) {
  StoreTemplate.load("farah-omar", "store", elements);
  return props.children;
}
