import React from "react";
import { FormattedMessage } from "@/i18n/i18n-client";
import styled from "styled-components";
import {
  useParams,
  usePathname,
  useRouter,
  useSearchParams,
} from "@/i18n/i18n-navigation";
import { parseSearchParams } from "@/shared/utils/search-params";

interface ClearAllFiltersProps {
  loading: boolean;
  plain?: boolean;
  className?: string;
}

const ClearAllFilters = ({
  loading,
  plain,
  className,
}: ClearAllFiltersProps) => {
  const params = useParams();
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const queries = parseSearchParams(searchParams);
  const isCollectionPage = !!params.collection;
  const isSearchPage = pathname.includes("search");

  return (
    <ClearFiltersButton
      className={className}
      disabled={
        loading || Object.keys(queries).length === 0 || isCollectionPage
      }
      plain={plain}
      onClick={() => {
        if (!isCollectionPage) {
          if (isSearchPage) {
            const query = queries.query ? `?query=${queries.query}` : "";
            router.push(`${pathname}${query}`);
          } else {
            // shop page
            router.push(pathname);
          }
        }
      }}
    >
      <FormattedMessage defaultMessage="Clear all" id="QW+Q5N" />
    </ClearFiltersButton>
  );
};

const ClearFiltersButton = styled.button<{ plain?: boolean }>`
  height: ${({ plain }) => (plain ? "auto" : "40px")};
  background: ${({ plain }) => (plain ? "transparent" : "#fafbfc")};
  border: ${({ plain }) => (plain ? "none" : "1px solid #e5e5e5")};
  cursor: pointer;
  border-radius: 4px;
  color: #5f738c;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: ${({ plain }) => (plain ? 0 : 1)};
`;

export default ClearAllFilters;
