import React, { useEffect, useState } from "react";
import { FormattedMessage } from "@/i18n/i18n-client";
import styled from "styled-components";
import { useStore } from "@/lib/storeData/StoreContext";
import {
  Section,
  StyledDiv,
  StyledFlexRowPrice,
  StyledInput,
  StyledLabel,
  StyledPrefix,
} from "../styled";
import {
  usePathname,
  useRouter,
  useSearchParams,
} from "@/i18n/i18n-navigation";
import {
  createSearchParams,
  parseSearchParams,
} from "@/shared/utils/search-params";

export const FilterByPrice = () => {
  const { currency } = useStore();
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const queries = parseSearchParams(searchParams);
  const [minValue, setMinValue] = useState(queries.minPrice || "");
  const [maxValue, setMaxValue] = useState(queries.maxPrice || "");
  useEffect(() => {
    setMinValue(queries.minPrice || "");
    setMaxValue(queries.maxPrice || "");
  }, [queries.minPrice, queries.maxPrice]);

  return (
    <Section>
      <StyledFlexRowPrice>
        <StyledLabel>
          <FormattedMessage defaultMessage="Min" id="A4AHkc" />
          <StyledDiv>
            <StyledPrefix>{currency}</StyledPrefix>
            <StyledInput
              value={minValue}
              type="number"
              onChange={(e) => setMinValue(e.target.value)}
              min="0"
              placeholder="500"
              onBlur={() => {
                if (
                  (!queries.minPrice && minValue === "") ||
                  parseFloat(minValue) >= parseFloat(maxValue)
                )
                  return;

                const { query } = createSearchParams({
                  ...queries,
                  page: 1,
                  minPrice: minValue,
                });
                router.push(`${pathname}?${query}`, { scroll: false });
              }}
            />
          </StyledDiv>
        </StyledLabel>

        <StyledLabel>
          <FormattedMessage defaultMessage="Max" id="kkjl2v" />
          <StyledDiv>
            <StyledPrefix>{currency}</StyledPrefix>
            <StyledInput
              value={maxValue}
              type="number"
              onChange={(e) => setMaxValue(e.target.value)}
              placeholder="1000"
              onBlur={() => {
                if (
                  (!queries.maxPrice && maxValue === "") ||
                  parseFloat(minValue) >= parseFloat(maxValue)
                )
                  return;
                const { query } = createSearchParams({
                  ...queries,
                  page: 1,
                  maxPrice: maxValue,
                });
                router.push(`${pathname}?${query}`, { scroll: false });
              }}
            />
          </StyledDiv>
        </StyledLabel>
      </StyledFlexRowPrice>
      {parseFloat(minValue) >= parseFloat(maxValue) && (
        <StyledErrorMessage>
          <FormattedMessage
            defaultMessage="Please make sure that min price is less than the max price"
            id="Uq+Yd8"
          />
        </StyledErrorMessage>
      )}
    </Section>
  );
};

const StyledErrorMessage = styled.p`
  color: red;
  font-size: 0.8rem;
  margin: 0;
  padding: 0;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 0.7rem;
  }
`;
