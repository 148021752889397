import styled from "styled-components";
import { FormattedMessage } from "@/i18n/i18n-client";
import { SortOrder } from "@/generated/graphql";
import { sortOptions } from "./consts";
import { ZIndex } from "@/shared/globals/types";
import { usePathname, useSearchParams } from "@/i18n/i18n-navigation";
import LoadingBar from "@/components/LoadingBar/LoadingBar";
import { useNavigateLoading } from "@/hooks/useNavigate";
import { LazySelect } from "@/shared/globals/LazyElements/react-select";

export const Sorting = () => {
  const [isFetching, navigate] = useNavigateLoading();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const handleChange = (selectedOption: {
    value: { sortBy: string; sortOrder: SortOrder };
  }) => {
    const params = new URLSearchParams(searchParams.toString());
    params.set("page", "1");

    if (selectedOption.value.sortBy) {
      params.set("sortBy", selectedOption.value.sortBy);
    }

    if (selectedOption.value.sortOrder) {
      params.set("sortOrder", selectedOption.value.sortOrder);
    }

    navigate(`${pathname}?${params.toString()}`);
  };

  const sort = {
    sortBy: searchParams.get("sortBy"),
    sortOrder: searchParams.get("sortOrder"),
  };

  return (
    <>
      <LoadingBar isLoading={isFetching} />
      <StyledSelect
        instanceId="select-sorting"
        options={sortOptions}
        onChange={handleChange}
        styles={customStyles}
        defaultValue={sortOptions.find(
          (option) => option.key === `${sort.sortBy}-${sort.sortOrder}`
        )}
        isSearchable={false}
        placeholder={
          <FormattedMessage defaultMessage="Default Sorting" id="mNK9is" />
        }
        components={{ IndicatorSeparator: () => null }}
      />
    </>
  );
};

// Functions

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    border: "solid 1px #dfe3e8",
    fontWeight: "500",
    cursour: "pointer",
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: "#252a31",
  }),
  menu: (provided: any) => ({
    ...provided,
    borderRadius: "4px",
    boxShadow: "0 2px 4px 0 rgba(82, 82, 82, 0.2)",
    border: "solid 1px  #dfe3e8",
    backgroundColor: "#fafbfc",
    zIndex: ZIndex["DROPDOWN"],
  }),

  option: (provided: any) => ({
    ...provided,
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "1.43",
    color: "#252a31",
    backgroundColor: "#fafbfc",
    cursor: "pointer",

    ":hover": {
      backgroundColor: "#dfe3e8",
    },
  }),

  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: "#252a31",
  }),
};

// Styles

const StyledSelect = styled(LazySelect)`
  min-width: 180px;
  max-height: 40px;
  border-radius: 4px;
  background-color: #fafbfc;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: ${({ theme }) => theme.text.secondary};
  cursor: pointer;
`;
