import { FC } from "react";
import { useLocale } from "@/i18n/i18n-client";
import { SectionHeadingProps } from "../../types";
import { Heading } from "@/components/UtilityComponents/Heading";

const SectionHeading: FC<SectionHeadingProps> = ({ section }) => {
  const { code: activeLocale } = useLocale();
  const translations = section?.translations?.find(
    (translation) => translation.locale === activeLocale
  );

  return section?.showSectionHeader ? (
    <Heading>{translations?.header || section?.header}</Heading>
  ) : null;
};

export default SectionHeading;
