import * as React from "react";
import { CollectionLink } from "../StoreLink/CollectionLink";
import { ExternalLink } from "../StoreLink/ExternalLink";
import { ProductLink } from "../StoreLink/ProductLink";
import { ShopLink } from "../StoreLink/ShopLink";
import { LinkType } from "../../generated/graphql";
import { PageLink } from "../StoreLink/PageLink";

type HeroBtnLinkProps = any & {
  children?: React.ReactNode;
};

export const HeroBtnLink: React.FC<HeroBtnLinkProps> = ({
  type,
  url,
  resource,
  children,
}) => {
  const handle = resource?.handle;

  if (!type) {
    return null;
  }

  return <>{LINK_COMPONENTS[type](handle, children, url)}</>;
};

const LINK_COMPONENTS = {
  [LinkType.Product]: (handle, children) => (
    <ProductLink handle={handle}>{children}</ProductLink>
  ),
  [LinkType.Collection]: (handle, children) => (
    <CollectionLink handle={handle}>{children}</CollectionLink>
  ),
  [LinkType.Page]: (handle, children) => (
    <PageLink handle={handle}>{children}</PageLink>
  ),
  [LinkType.ExternalLink]: (handle, children, url) => (
    <ExternalLink url={url} openInNewTab={true}>
      {children}
    </ExternalLink>
  ),
  [LinkType.Shop]: (handle, children) => (
    <ShopLink url="/shop">{children}</ShopLink>
  ),
};

export const checkLinkTypeAndGetUrl = ({
  type,
  handle,
  url,
}: {
  type: LinkType;
  handle: string;
  url: string;
}) => {
  switch (type) {
    case LinkType.Product:
      return `/product/all/${handle}`;
    case LinkType.Collection:
      return `/product/${handle}`;
    case LinkType.Page:
      return `/page/${handle}`;
    case LinkType.ExternalLink: {
      const realURL = url?.includes("http") ? url : "http://" + url;
      return realURL;
    }
    case LinkType.Shop:
      return "/shop";
    default:
      return null;
  }
};
