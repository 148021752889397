import React, { FC } from "react";
import { PageHeaderProps } from "../../types";

const PageHeader: FC<PageHeaderProps> = ({ pageTitle }) => {
  return (
    <div className="w-full min-h-24 flex items-center place-content-center bg-[##252a311a]">
      <h1 className="uppercase text-center m-0 p-0 leading-tight text-2xl font-black text-[##252a31]">
        {pageTitle}
      </h1>
    </div>
  );
};

export default PageHeader;
