import React from "react";
import dynamic from "next/dynamic";
import { StoreTemplate } from "@/templates/TemplateLoader";
import { FormattedDataType } from "@/templates/types";

const ReactPlayer = dynamic(() => import("react-player/lazy"), { ssr: false });

interface VideoCardProps {
  isSliderSection?: boolean;
  section: Partial<FormattedDataType>;
  link: FormattedDataType["links"][0];
}

const VideoCard: React.FC<VideoCardProps> = ({
  section,
  isSliderSection,
  link,
}) => {
  const Template = StoreTemplate.get();

  return (
    <Template.SectionCardWrapper
      isSlider={isSliderSection}
      data-test="link-card"
      background={section?.background}
    >
      <ReactPlayer url={link} width="100%" height="350px" />
    </Template.SectionCardWrapper>
  );
};

export default VideoCard;
