import { Link } from "@/i18n/i18n-navigation";
import * as React from "react";

interface PageLinkProps {
  handle: string;
  children?: React.ReactNode;
}

export const PageLink: React.FC<PageLinkProps> = ({ handle, children }) => {
  return <Link href={`/page/${handle}`}>{children}</Link>;
};
