import React from "react";
import styled from "styled-components";
import { Heading } from "@/components/UtilityComponents/Heading";
import HTMLRender from "@/shared/globals/UiElements/HTMLRender";
import { Section } from "@/shared/layout";
import { HomeContentSectionProps } from "../../types";
import { useLocale } from "@/i18n/i18n-client";

const HomeContentSection: React.FC<HomeContentSectionProps> = ({ section }) => {
  const { code: activeLocale } = useLocale();
  const translations = section?.translations?.find(
    (translation) => translation.locale === activeLocale
  );

  return (
    <StyledSection stretchToFullWidth={section?.stretchToFullWidth}>
      {section?.showSectionHeader && (
        <Heading>{translations?.header || section?.header}</Heading>
      )}
      <HTMLRender html={translations?.content || section?.content} />
    </StyledSection>
  );
};

export default HomeContentSection;

const StyledSection = styled(Section)<{ stretchToFullWidth?: boolean }>`
  && {
    max-width: ${({ stretchToFullWidth }) => stretchToFullWidth && "100vw"};
    padding: ${({ stretchToFullWidth }) => stretchToFullWidth && "unset"};
  }
`;
