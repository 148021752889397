import { LinkType } from "../../generated/graphql";

export function getLink(type?: LinkType, handle?: string) {
  switch (type) {
    case LinkType.Product:
      return `/product/all/${handle}`;
    case LinkType.Collection:
      return `/product/${handle}`;
    case LinkType.Page:
      return `/page/${handle}`;
    default:
      return "/shop";
  }
}
